/*custom font*/
@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Bold"),
    url("./fonts/MPLUSRounded1c-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-ExtraBold"),
    url("./fonts/MPLUSRounded1c-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Light"),
    url("./fonts/MPLUSRounded1c-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Medium"),
    url("./fonts/MPLUSRounded1c-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Regular"),
    url("./fonts/MPLUSRounded1c-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Thin"),
    url("./fonts/MPLUSRounded1c-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: M PLUS Rounded\1c;
  src: local("MPLUSRounded1c-Black"),
    url("./fonts/MPLUSRounded1c-Black.ttf") format("truetype");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: M PLUS Rounded\1c, sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #972679;
  --primary-color-card: rgba(151, 38, 121, 0.05);
  --secondary-color: #514f97;
  --tetiary-color: #f60e71;
  --grey-color: #84848469;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: M PLUS Rounded\1c;
}
