
.navbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  padding: 0 2rem;
}

nav .nav-close-btn{
  visibility: hidden;
  display: none;
  opacity: 0;
  cursor: pointer;
  background: transparent;
  border: none;
}

.hamburger__section{
  display: none;
}


.custom__modal{
  font-family:  M PLUS Rounded\ 1c;
}

header .nav-btn{
  padding: 5px;
  visibility: hidden;
  display: none;
  opacity: 0;
  cursor: pointer;
  background: transparent;
  border: none;
}

header nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}


.parent{
  background-color: white;
  position: relative;
  /* border: red solid 5px; */
  height: 100%;
  font-family: M PLUS Rounded\ 1c;

}

.first_child{
  position: static;
  width: 100%;
  height: 100%;
}

.second_child{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 10000;
}

.second_child.active{
  visibility: visible;
}

/*cover*/
.cover{
  padding: 1rem;
  background-color: transparent;
}

.snip1533 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  display: inline-block;
  font-size: 16px;
  margin: 0;
  text-align: center;
  width: 100%;
  background-color: var(--primary-color-card);
  border-radius: 5px;
  border-top: 5px solid var(--primary-color);
}

.snip1533 figcaption {
  padding: 13% 10% 12%;
}


.snip__text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 400;
  color: #000;
}

.snip1533 h3 {
  color:black;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 10px 0 5px;
}

.snip1533 h5 {
  font-weight: 600;
  margin: 0;
  opacity: 0.5;
}

.snip1533 blockquote {
  font-style: italic;
  font-weight: 300;
  margin: 0 0 20px;
}

.footer-class{
  color: white;
  background-image: linear-gradient(90deg, var(--tetiary-color), var(--secondary-color), var(--primary-color));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.dynamic_card{
  width: 50%;
}

@media only screen and (max-width: 1400px) {
  .dynamic_card{
    width: 45%;
  }
}

.nav__links{
  font-weight: 600;
  text-decoration: none;
  color: black;
  font-size: 1rem;
}


@media only screen  and (max-width: 1200px){
  .dynamic_card{
    width: 50%;
  }

}


/*mediaquery*/
@media only screen and (max-width:992px) {

  .dynamic_card{
    width: 65%;
  }
  .hamburger__section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  
  header .nav-btn{
    display: block;
    visibility: visible;
    opacity: 1;
  }

  header nav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    transition: 250ms;
    z-index: 9999;
    background-color: white;
    transform: translateY(-100vh);
  }

  header .responsive-nav{
    transform: none;
  }

  nav .nav-close-btn{
    visibility: visible;
    position: absolute;
    display: block;
    opacity: 1;

    top: 2rem;
    right: 2rem;
    cursor: pointer;
    background: transparent;
    border: none;
  }
} 


.whychooseus__li{
  position: relative;
  padding-left: 1.5rem;
}

.whychooseus__li::before{
  content: "\2605";
  left: 0;
  position: absolute;
}



@media only screen and (max-width: 768px) {
  
  .dynamic_card{
    width: 75%;
  }
  .cover{
    background-color: white;
    margin: 1rem 0rem;
    border-radius: 4px;
    box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.192);
  }
}

/*breaking points*/
@media only screen and (max-width: 576px) {
  
  .dynamic_card{
    width: 100%;
  }
  .cover{
    margin: 1rem 0rem;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 10px 10px 0 rgba(0, 0, 0, 0.192);
  }
}

