
.react-datepicker__header {
    text-align: center;
    background-color: var(--primary-color);
    border-bottom: 1px solid #ffffff;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
  margin-top: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.944rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__time-name {
  color: #ffffff;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  }
  